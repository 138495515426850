import React from 'react';
import { AppBar, Toolbar, Typography, MenuItem, Select, Box, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavDrawer from './NavDrawer';
import { useBasemap } from '../BaseMapProvider';
import basemapStyles from '../resources/baseMapConfig';
import { useNavigate, useLocation } from 'react-router-dom'; 

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : theme.palette.background.paper,
  color: theme.palette.mode === 'light' ? '#333' : '#fff',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  borderTop: `1px solid ${theme.palette.primary.main}`,
  position: 'relative',
  zIndex: theme.zIndex.drawer + 1,
}));

const HeaderContainer = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
}));

const FancyTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const RightContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}));

const BasemapContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.text.primary,
  },
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
}));

const Header = () => {
  const navigate = useNavigate(); 
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const { basemap, setBasemap } = useBasemap();

  const handleBasemapChange = (event) => {
    setBasemap(event.target.value);
  };

  const navigateHome = () => {
    navigate('/'); 
  };  

  const getHeaderTitle = () => {
    if (pathname === '/') {
      return t('app.projectList');
    } else if (pathname.includes('/projectreport')) {
      return t('app.projectEvaluationResult');
    } else if (pathname === '/project') {
      return t('app.addProject'); 
    } else if (pathname.includes('/project') && pathname.split('/').length > 2) {
      return t('app.editProject');
    }
    return '';
  };  

  return (
    <StyledAppBar position="sticky">
      <Typography 
        variant='h5' 
        sx={{ 
          position: 'absolute', 
          left: '25%', 
          top: "30%", 
          fontWeight: 'bold'
        }} 
      >
      {getHeaderTitle()}
      </Typography>
      <HeaderContainer>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }} onClick={navigateHome}>
         <img src='/nature-ky-128.png' alt={t('app.title')}  className="brand-logo"/>
        </Box>
        <RightContainer>
          <BasemapContainer>
            <FancyTypography variant="body1">
              {t('app.basemap')}
            </FancyTypography>
            <Box sx={{ minWidth: 120 }}>
              <StyledSelect
                id='basename'
                name='basename'
                value={basemap}
                onChange={handleBasemapChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Basemap' }}
              >
                {basemapStyles.map((style) => (
                  <MenuItem key={style.value} value={style.value}>
                    {t(style.translationKey)}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
          </BasemapContainer>
          <NavDrawer />
        </RightContainer>
      </HeaderContainer>
    </StyledAppBar>
  );
};

export default Header;

